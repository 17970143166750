import React from "react";

const KostasLogoIcon = () => {
  return (
    <svg
      width="170"
      height="16"
      viewBox="0 0 170 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Kostas Kazazis">
        <path
          d="M5.22452 9.03297L3.05301 11.8681V15.9121H0V0.0879123H3.05301V7.51648L8.40653 0.0879123H12.04L7.28853 6.32967L12.2766 15.9121H8.79354L5.22452 9.03297Z"
          fill="white"
        />
        <path
          d="M16.377 0H21.5585C23.3645 0 24.6116 1.27473 24.6116 3.12088V12.8791C24.6116 14.7253 23.3645 16 21.5585 16H16.377C14.571 16 13.324 14.7253 13.324 12.8791V3.12088C13.324 1.27473 14.571 0 16.377 0ZM21.5155 12.2637V3.73626C21.5155 3.25275 21.2575 2.98901 20.7845 2.98901H17.151C16.678 2.98901 16.42 3.25275 16.42 3.73626V12.2637C16.42 12.7473 16.678 13.011 17.151 13.011H20.7845C21.2575 13.011 21.5155 12.7473 21.5155 12.2637Z"
          fill="white"
        />
        <path
          d="M34.876 4.96703V3.73626C34.876 3.25275 34.618 2.98901 34.145 2.98901H31.049C30.576 2.98901 30.318 3.25275 30.318 3.73626V5.23077C30.318 5.71429 30.576 5.86813 31.049 5.97802L35.048 6.85714C36.725 7.23077 38.144 8.26374 38.144 10.022V12.8791C38.144 14.7253 36.897 16 35.091 16H30.275C28.469 16 27.222 14.7253 27.222 12.8791V10.8571H30.232V12.2637C30.232 12.7473 30.49 13.011 30.963 13.011H34.317C34.79 13.011 35.048 12.7473 35.048 12.2637V10.5934C35.048 10.1099 34.79 9.95604 34.317 9.84615L30.318 8.96703C28.641 8.59341 27.222 7.56044 27.222 5.8022V3.12088C27.222 1.27473 28.469 0 30.275 0H34.833C36.639 0 37.886 1.27473 37.886 3.12088V4.96703H34.876Z"
          fill="white"
        />
        <path
          d="M50.8986 3.03297H46.8566V15.9121H43.7606V3.03297H39.7186V0.0879123H50.8986V3.03297Z"
          fill="white"
        />
        <path
          d="M54.5946 12.3516L53.5626 15.9121H50.3591L55.3471 0.0879123H58.9591L63.9471 15.9121H60.6146L59.6041 12.3516H54.5946ZM57.1316 3.56044L55.4546 9.36264H58.7656L57.1316 3.56044Z"
          fill="white"
        />
        <path
          d="M72.9631 4.96703V3.73626C72.9631 3.25275 72.7051 2.98901 72.2321 2.98901H69.136C68.663 2.98901 68.405 3.25275 68.405 3.73626V5.23077C68.405 5.71429 68.663 5.86813 69.136 5.97802L73.1351 6.85714C74.8121 7.23077 76.2311 8.26374 76.2311 10.022V12.8791C76.2311 14.7253 74.9841 16 73.1781 16H68.362C66.556 16 65.309 14.7253 65.309 12.8791V10.8571H68.319V12.2637C68.319 12.7473 68.577 13.011 69.05 13.011H72.4041C72.8771 13.011 73.1351 12.7473 73.1351 12.2637V10.5934C73.1351 10.1099 72.8771 9.95604 72.4041 9.84615L68.405 8.96703C66.728 8.59341 65.309 7.56044 65.309 5.8022V3.12088C65.309 1.27473 66.556 0 68.362 0H72.9201C74.7261 0 75.9731 1.27473 75.9731 3.12088V4.96703H72.9631Z"
          fill="white"
        />
        <path
          d="M90.0701 9.03297L87.8986 11.8681V15.9121H84.8456V0.0879123H87.8986V7.51648L93.2521 0.0879123H96.8856L92.1341 6.32967L97.1221 15.9121H93.6391L90.0701 9.03297Z"
          fill="white"
        />
        <path
          d="M102.025 12.3516L100.993 15.9121H97.7894L102.777 0.0879123H106.389L111.377 15.9121H108.045L107.034 12.3516H102.025ZM104.562 3.56044L102.885 9.36264H106.196L104.562 3.56044Z"
          fill="white"
        />
        <path
          d="M112.586 13.4945L119.552 3.03297H112.973V0.0879123H123.465V2.50549L116.499 12.967H123.422V15.9121H112.586V13.4945Z"
          fill="white"
        />
        <path
          d="M128.9 12.3516L127.868 15.9121H124.665L129.653 0.0879123H133.265L138.253 15.9121H134.92L133.91 12.3516H128.9ZM131.437 3.56044L129.76 9.36264H133.071L131.437 3.56044Z"
          fill="white"
        />
        <path
          d="M139.461 13.4945L146.427 3.03297H139.848V0.0879123H150.34V2.50549L143.374 12.967H150.297V15.9121H139.461V13.4945Z"
          fill="white"
        />
        <path
          d="M153.02 15.9121V0.0879123H156.116V15.9121H153.02Z"
          fill="white"
        />
        <path
          d="M166.732 4.96703V3.73626C166.732 3.25275 166.474 2.98901 166.001 2.98901H162.905C162.432 2.98901 162.174 3.25275 162.174 3.73626V5.23077C162.174 5.71429 162.432 5.86813 162.905 5.97802L166.904 6.85714C168.581 7.23077 170 8.26374 170 10.022V12.8791C170 14.7253 168.753 16 166.947 16H162.131C160.325 16 159.078 14.7253 159.078 12.8791V10.8571H162.088V12.2637C162.088 12.7473 162.346 13.011 162.819 13.011H166.173C166.646 13.011 166.904 12.7473 166.904 12.2637V10.5934C166.904 10.1099 166.646 9.95604 166.173 9.84615L162.174 8.96703C160.497 8.59341 159.078 7.56044 159.078 5.8022V3.12088C159.078 1.27473 160.325 0 162.131 0H166.689C168.495 0 169.742 1.27473 169.742 3.12088V4.96703H166.732Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default KostasLogoIcon;
