import React from "react";
import Layout from "./src/components/Layout/Layout";
import "./src/colors.css";

const wrapPageElement = ({ element, props }) => {
  return (
    <>
      <Layout {...props}>{element}</Layout>
    </>
  );
};

export { wrapPageElement };
