import React from 'react'
import Flex from '../Box/Flex'
import {styled} from 'styled-components'
import InstagramIcon from '../svg/InstagramIcon'
import {Text} from '../Text/Text'
import {AUCTION_URL, INSTAGRAM_URL} from '../../config/data'
import KostasLogoIcon from '../svg/KostasLogoIcon'

const MainWrapper = styled(Flex)`
	position: absolute;
	width: 100%;
	z-index: 10;
	padding: 32px;
	margin: 0 auto;
	max-width: 1232px;
	align-items: center;
	gap: 32px;
	justify-content: space-between;
	z-index: 99;
	@media (max-width: 900px) {
		padding: 24px;
	}
`

const ContentWrapper = styled(Flex)`
	align-items: center;
	@media (max-width: 900px) {
		align-items: initial;
		flex-direction: column;
		gap: 8px;
	}
`

const StyledButton = styled.button`
	padding: 8px 42px;
	@media (max-width: 900px) {
		font-size: 16px;
		padding: 8px 8px;
	}
`

const Header: React.FC = () => {
	return (
		<Flex justifyContent='center'>
			<MainWrapper>
				<ContentWrapper gap='32px'>
					<KostasLogoIcon />
					<Text fontSize='14px' colored>
						Konstantinos - Ioannis, Kazazis
					</Text>
				</ContentWrapper>
				<ContentWrapper gap='16px'>
					<Flex
						alignItems='center'
						gap='4px'
						style={{cursor: 'pointer'}}
						onClick={() => window.open(INSTAGRAM_URL, '_blank')}
					>
						<InstagramIcon />
						<Text>Instagram</Text>
					</Flex>
					<StyledButton onClick={() => window.open(AUCTION_URL, '_blank')}>
						Buy NFT
					</StyledButton>
				</ContentWrapper>
			</MainWrapper>
		</Flex>
	)
}

export default Header
