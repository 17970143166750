import React from "react";
import styled from "styled-components";
import { Flex } from "../Box";
import { Text } from "../Text/Text";

const MainWrapper = styled(Flex)`
  margin: 100px 0;
  width: 100%;
  max-width: 1232px;
  @media (max-width: 900px) {
    padding: 0 24px;
  }
`;

const Footer: React.FC = () => {
  return (
    <Flex justifyContent="center">
      <MainWrapper>
        <Text fontSize="14px">
          Copyright © 2023 kostaskazazis.com | All rights reserved
        </Text>
      </MainWrapper>
    </Flex>
  );
};

export default Footer;
