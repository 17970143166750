import { TileProps } from "../components/Roadmap/Tile";
import HiveLogo from "../images/Partners/Hive.png";
import MetaproLogo from "../images/Partners/metapro.png";
import KBLogo from "../images/Partners/KB.png";

export const INSTAGRAM_URL = "https://www.instagram.com/kwstaskazz/";
export const AUCTION_URL =
  "https://metapromarket.com/auction/buy/59/asset/56/0xa293d68684be29540838dc8a0222de0c43c6b5b4/1185";

export const roadmapData: TileProps[] = [
  {
    date: "5.10.2023",
    description:
      "Poznan Racing Track eliminationton Karol Basz Pro Racing Team",
  },
  {
    date: "5.10.2023",
    description:
      "Poznan Racing Track eliminationton Karol Basz Pro Racing Team",
  },
  {
    date: "5.10.2023",
    description:
      "Poznan Racing Track eliminationton Karol Basz Pro Racing Team",
  },
  {
    date: "5.10.2023",
    description:
      "Poznan Racing Track eliminationton Karol Basz Pro Racing Team",
  },
  {
    date: "5.10.2023",
    description:
      "Poznan Racing Track eliminationton Karol Basz Pro Racing Team",
  },
];

export const partnersData: { logo: string; url?: string }[] = [
  { logo: HiveLogo },
  { logo: MetaproLogo, url: "https://www.metapromarket.com/" },
  { logo: KBLogo, url: "https://kbasz.pl/" },
];
