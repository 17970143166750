import { styled } from "styled-components";
import {
  TypographyProps,
  SpaceProps,
  ColorProps,
  LayoutProps,
} from "styled-system";
import {
  compose,
  typography,
  space,
  color,
  layout,
  position,
  background,
} from "styled-system";

type TextProps = TypographyProps &
  SpaceProps &
  ColorProps &
  LayoutProps & { colored?: boolean } & { bold?: boolean };

export const Text = styled.div<TextProps>`
  ${(props) =>
    props.colored
      ? `
    color: var(--primary-color);
  `
      : ""}

  font-weight: ${(props) => (props.bold ? "700" : "normal")};
  ${compose(typography, space, color, layout, position, background)}
  font-family:"Syne", sans-serif;
`;
